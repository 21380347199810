export interface ITranslatedRoutedSlugObject {
    [lang: string]: string;
}

const translatedRoutesSlugs: {
    [key: string]: ITranslatedRoutedSlugObject;
} = {
    "/": {
        en: "",
        bn: "",
        es: "",
        de: "",
        si: "",
        fr: "",
        it: "",
        pt: "",
        nl: "",
        ja: "",
        zh: "",
        ko: "",
        sv: "",
        tr: "",
        id: "",
        pl: "",
    },
    "/resize/editor/:taskId": {
        en: "resize/editor/:taskId",
        bn: "আকার-পরিবর্তন/সম্পাদক/:taskId",
        es: "cambiar-el-tamaño/editor/:taskId",
        de: "größe-ändern/editor/:taskId",
        si: "ප්රමාණය-වෙනස්-කරන්න/සංස්කාරක/:taskId",
        fr: "redimensionner/éditeur/:taskId",
        it: "ridimensionare/editore/:taskId",
        pt: "redimensionar/editor/:taskId",
        nl: "formaat-wijzigen/editor/:taskId",
        ja: "サイズ変更/エディター/:taskId",
        zh: "调整大小/编辑/:taskId",
        ko: "크기-변경/편집기/:taskId",
        sv: "ändra-storlek/redigerare/:taskId",
        tr: "boyutlandır/düzenleyici/:taskId",
        id: "ubah-ukuran/editor/:taskId",
        pl: "zmień-rozmiar/edytor/:taskId",
    },

    "/resize/download/:jobId": {
        en: "resize/download/:jobId",
        bn: "আকার-পরিবর্তন/ডাউনলোড/:jobId",
        es: "cambiar-el-tamaño/descargar/:jobId",
        de: "größe-ändern/download/:jobId",
        si: "ප්රමාණය-වෙනස්-කරන්න/බාගත/:jobId",
        fr: "redimensionner/téléchargement/:jobId",
        it: "ridimensionare/scarica/:jobId",
        pt: "redimensionar/download/:jobId",
        nl: "formaat-wijzigen/download/:jobId",
        ja: "サイズ変更/ダウンロード/:jobId",
        zh: "调整大小/下载/:jobId",
        ko: "크기-변경/다운로드/:jobId",
        sv: "ändra-storlek/ladda-ner/:jobId",
        tr: "boyutlandır/indir/:jobId",
        id: "ubah-ukuran/unduh/:jobId",
        pl: "zmień-rozmiar/pobierz/:jobId",
    },
    "/resize-jpg": {
        en: "resize-jpg",
        bn: "আকার-পরিবর্তন-jpg",
        es: "cambiar-el-tamaño-jpg",
        de: "größe-ändern-jpg",
        si: "ප්රමාණය-වෙනස්-කරන්න-jpg",
        fr: "redimensionner-jpg",
        it: "ridimensionare-jpg",
        pt: "redimensionar-jpg",
        nl: "redimensioneren-jpg",
        ja: "jpg-リサイズ",
        zh: "调整大小-jpg",
        ko: "jpg-크기-변경",
        sv: "ändra-storlek-jpg",
        tr: "jpg-boyutlandır",
        id: "ubah-ukuran-jpg",
        pl: "zmień-rozmiar-jpg",
    },
    "/resize-jpg/editor/:taskId": {
        en: "/resize-jpg/editor/:taskId",
        bn: "/আকার-পরিবর্তন-png/সম্পাদক/:taskId",
        es: "/cambiar-el-tamaño-png/editor/:taskId",
        de: "/größe-ändern-png/editor/:taskId",
        si: "/ප්රමාණය-වෙනස්-කරන්න-png/සංස්කාරක/:taskId",
        fr: "/redimensionner-png/éditeur/:taskId",
        it: "/ridimensionare-png/editore/:taskId",
        pt: "/redimensionar-png/editor/:taskId",
        nl: "/redimensioneren-png/editor/:taskId",
        ja: "/png-リサイズ/エディター/:taskId",
        zh: "/调整大小-png/编辑/:taskId",
        ko: "/png-크기-변경/편집기/:taskId",
        sv: "/ändra-storlek-png/redigerare/:taskId",
        tr: "/png-boyutlandır/düzenleyici/:taskId",
        id: "/ubah-ukuran-png/editor/:taskId",
        pl: "/zmień-rozmiar-png/edytor/:taskId",


    },
    "/resize-png": {
        en: "resize-png",
        bn: "আকার-পরিবর্তন-png",
        es: "cambiar-el-tamaño-png",
        de: "größe-ändern-png",
        si: "ප්රමාණය-වෙනස්-කරන්න-png",
        fr: "redimensionner-png",
        it: "ridimensionare-png",
        pt: "redimensionar-png",
        nl: "redimensioneren-png",
        ja: "png-リサイズ",
        zh: "调整大小-png",
        ko: "png-크기-변경",
        sv: "ändra-storlek-png",
        tr: "png-boyutlandır",
        id: "ubah-ukuran-png",
        pl: "zmień-rozmiar-png",
    },

    "/resize-png/editor/:taskId": {
        en: "resize-png/editor/:taskId",
        bn: "আকার-পরিবর্তন-png/সম্পাদক/:taskId",
        es: "cambiar-el-tamaño-png/editor/:taskId",
        de: "größe-ändern-png/editor/:taskId",
        si: "ප්රමාණය-වෙනස්-කරන්න-png/සංස්කාරක/:taskId",
        fr: "redimensionner-png/éditeur/:taskId",
        it: "ridimensionare-png/editore/:taskId",
        pt: "redimensionar-png/editor/:taskId",
        nl: "redimensioneren-png/editor/:taskId",
        ja: "png-リサイズ/エディター/:taskId",
        zh: "调整大小-png/编辑/:taskId",
        ko: "png-크기-변경/편집기/:taskId",
        sv: "ändra-storlek-png/redigerare/:taskId",
        tr: "png-boyutlandır/düzenleyici/:taskId",
        id: "ubah-ukuran-png/editor/:taskId",
        pl: "zmień-rozmiar-png/edytor/:taskId",

    },
    "/resize-webp": {
        en: "resize-webp",
        bn: "আকার-পরিবর্তন-webp",
        es: "cambiar-el-tamaño-webp",
        de: "größe-ändern-webp",
        si: "ප්රමාණය-වෙනස්-කරන්න-webp",
        fr: "redimensionner-webp",
        it: "ridimensionare-webp",
        pt: "redimensionar-webp",
        nl: "redimensioneren-webp",
        ja: "webp-リサイズ",
        zh: "调整大小-webp",
        ko: "webp-크기-변경",
        sv: "ändra-storlek-webp",
        tr: "webp-boyutlandır",
        id: "ubah-ukuran-webp",
        pl: "zmień-rozmiar-webp",
    },
    "/resize-webp/editor/:taskId": {
        en: "resize-webp/editor/:taskId",
        bn: "আকার-পরিবর্তন-webp/সম্পাদক/:taskId",
        es: "cambiar-el-tamaño-webp/editor/:taskId",
        de: "größe-ändern-webp/editor/:taskId",
        si: "ප්රමාණය-වෙනස්-කරන්න-webp/සංස්කාරක/:taskId",
        fr: "redimensionner-webp/éditeur/:taskId",
        it: "ridimensionare-webp/editore/:taskId",
        pt: "redimensionar-webp/editor/:taskId",
        nl: "redimensioneren-webp/editor/:taskId",
        ja: "webp-リサイズ/エディター/:taskId",
        zh: "调整大小-webp/编辑/:taskId",
        ko: "webp-크기-변경/편집기/:taskId",
        sv: "ändra-storlek-webp/redigerare/:taskId",
        tr: "webp-boyutlandır/düzenleyici/:taskId",
        id: "ubah-ukuran-webp/editor/:taskId",
        pl: "zmień-rozmiar-webp/edytor/:taskId",

    },
    "/resize-jpg/download/:jobId": {
        en: "resize-jpg/download/:jobId",
        bn: "আকার-পরিবর্তন-jpg/ডাউনলোড/:jobId",
        es: "cambiar-el-tamaño-jpg/descargar/:jobId",
        de: "größe-ändern-jpg/download/:jobId",
        si: "ප්රමාණය-වෙනස්-කරන්න-jpg/බාගත/:jobId",
        fr: "redimensionner-jpg/téléchargement/:jobId",
        it: "ridimensionare-jpg/scarica/:jobId",
        pt: "redimensionar-jpg/download/:jobId",
        nl: "redimensioneren-jpg/download/:jobId",
        ja: "jpg-リサイズ/ダウンロード/:jobId",
        zh: "调整大小-jpg/下载/:jobId",
        ko: "jpg-크기-변경/다운로드/:jobId",
        sv: "ändra-storlek-jpg/ladda-ner/:jobId",
        tr: "jpg-boyutlandır/indir/:jobId",
        id: "ubah-ukuran-jpg/unduh/:jobId",
        pl: "zmień-rozmiar-jpg/pobierz/:jobId",
    },
    "/resize-png/download/:jobId": {
        en: "resize-png/download/:jobId",
        bn: "আকার-পরিবর্তন-png/ডাউনলোড/:jobId",
        es: "cambiar-el-tamaño-png/descargar/:jobId",
        de: "größe-ändern-png/download/:jobId",
        si: "ප්රමාණය-වෙනස්-කරන්න-png/බාගත/:jobId",
        fr: "redimensionner-png/téléchargement/:jobId",
        it: "ridimensionare-png/scarica/:jobId",
        pt: "redimensionar-png/download/:jobId",
        nl: "redimensioneren-png/download/:jobId",
        ja: "png-リサイズ/ダウンロード/:jobId",
        zh: "调整大小-png/下载/:jobId",
        ko: "png-크기-변경/다운로드/:jobId",
        sv: "ändra-storlek-png/ladda-ner/:jobId",
        tr: "png-boyutlandır/indir/:jobId",
        id: "ubah-ukuran-png/unduh/:jobId",
    },
    "/resize-webp/download/:jobId": {
        en: "resize-webp/download/:jobId",
        bn: "আকার-পরিবর্তন-webp/ডাউনলোড/:jobId",
        es: "cambiar-el-tamaño-webp/descargar/:jobId",
        de: "größe-ändern-webp/download/:jobId",
        si: "ප්රමාණය-වෙනස්-කරන්න-webp/බාගත/:jobId",
        fr: "redimensionner-webp/téléchargement/:jobId",
        it: "ridimensionare-webp/scarica/:jobId",
        pt: "redimensionar-webp/download/:jobId",
        nl: "redimensioneren-webp/download/:jobId",
        ja: "webp-リサイズ/ダウンロード/:jobId",
        zh: "调整大小-webp/下载/:jobId",
        ko: "webp-크기-변경/다운로드/:jobId",
        sv: "ändra-storlek-webp/ladda-ner/:jobId",
        tr: "webp-boyutlandır/indir/:jobId",
        id: "ubah-ukuran-webp/unduh/:jobId",
    },
    "/crop-image": {
        en: "crop-image",
        bn: "ছবি-ক্রপ",
        es: "recortar-imagen",
        de: "bild-zuschneiden",
        si: "රූපය-කප්පාදු",
        fr: "recadrer-l'image",
        it: "ritaglia-l'immagine",
        pt: "cortar-imagem",
        nl: "afbeelding-bijsnijden",
        ja: "画像を切り抜く",
        zh: "裁剪图像",
        ko: "이미지-자르기",
        sv: "beskär-bild",
        tr: "resim-kes",
        id: "potong-gambar",
        pl: "przytnij-obraz",
    },
    "/crop-image/editor": {
        en: "crop-image/editor",
        bn: "ছবি-ক্রপ/সম্পাদক",
        es: "recortar-imagen/editor",
        de: "bild-zuschneiden/editor",
        si: "රූපය-කප්පාදු/සංස්කාරක",
        fr: "recadrer-l'image/éditeur",
        it: "ritaglia-l'immagine/editore",
        pt: "cortar-imagem/editor",
        nl: "afbeelding-bijsnijden/editor",
        ja: "画像を切り抜く/エディター",
        zh: "裁剪图像/编辑",
        ko: "이미지-자르기/편집기",
        sv: "beskär-bild/redigerare",
        tr: "resim-kes/düzenleyici",
        id: "potong-gambar/editor",
        pl: "przytnij-obraz/edytor",
    },
    "/image-compressor": {
        en: "image-compressor",
        bn: "ইমেজ-কম্প্রেসার",
        es: "compresor-de-imagen",
        de: "bild-kompressor",
        si: "රූප-සම්පීඩකය",
        fr: "compresseur-d'images",
        it: "compressore-di-immagini",
        pt: "compressor-de-imagem",
        nl: "afbeelding-compressor",
        ja: "画像圧縮",
        zh: "图像压缩",
        ko: "이미지-압축기",
        sv: "bildkompressor",
        tr: "görüntü-kompresörü",
        id: "kompresor-gambar",
        pl: "kompresor-obrazów",
    },
    "/image-compressor/editor": {
        en: "image-compressor/editor",
        bn: "ইমেজ-কম্প্রেসার/সম্পাদক",
        es: "compresor-de-imagen/editor",
        de: "bild-kompressor/editor",
        si: "රූප-සම්පීඩකය/සංස්කාරක",
        fr: "compresseur-d'images/éditeur",
        it: "compressore-di-immagini/editor",
        pt: "compressor-de-imagem/editor",
        nl: "afbeelding-compressor/editor",
        ja: "画像圧縮/エディター",
        zh: "图像压缩/编辑",
        ko: "이미지-압축기/편집기",
        sv: "bildkompressor/redigerare",
        tr: "görüntü-kompresörü/düzenleyici",
        id: "kompresor-gambar/editor",
        pl: "kompresor-obrazów/edytor",
    },
    "/compress-jpeg": {
        en: "compress-jpeg",
        bn: "কম্প্রেস-jpeg",
        es: "comprimir-jpeg",
        de: "JPEG-komprimieren",
        si: "සම්පීඩනය-jpeg",
        fr: "compresser-jpeg",
        it: "comprimere-jpeg",
        pt: "compactar-jpeg",
        nl: "jpeg-comprimeren",
        ja: "jpeg圧縮",
        zh: "压缩jpeg",
        ko: "jpeg-압축",
        sv: "komprimera-jpeg",
        tr: "jpeg-sıkıştır",
        id: "kompres-jpeg",
        pl: "kompresuj-jpeg",
    },
    "/compress-jpeg/editor": {
        en: "compress-jpeg/editor",
        bn: "কম্প্রেস-jpeg/সম্পাদক",
        es: "comprimir-jpeg/editor",
        de: "JPEG-komprimieren/editor",
        si: "සම්පීඩනය-jpeg/සංස්කාරක",
        fr: "compresser-jpeg/éditeur",
        it: "comprimere-jpeg/editore",
        pt: "compactar-jpeg/editor",
        nl: "jpeg-comprimeren/editor",
        ja: "jpeg圧縮/エディター",
        zh: "压缩jpeg/编辑",
        ko: "jpeg-압축/편집기",
        sv: "komprimera-jpeg/redigerare",
        tr: "jpeg-sıkıştır/düzenleyici",
        id: "kompres-jpeg/editor",
        pl: "kompresuj-jpeg/edytor",
    },
    "/png-compressor": {
        en: "png-compressor",
        bn: "কম্প্রেস-png",
        es: "comprimir-png",
        de: "PNG-komprimieren",
        si: "සම්පීඩනය-png",
        fr: "compresser-png",
        it: "comprimere-png",
        pt: "compactar-png",
        nl: "png-comprimeren",
        ja: "png圧縮",
        zh: "压缩png",
        ko: "png-압축기",
        sv: "komprimera-png",
        tr: "png-sıkıştır",
        id: "kompres-png",
        pl: "kompresuj-png",
    },
    "/png-compressor/editor": {
        en: "png-compressor/editor",
        bn: "কম্প্রেস-png/সম্পাদক",
        es: "comprimir-png/editor",
        de: "PNG-komprimieren/editor",
        si: "සම්පීඩනය-png/සංස්කාරක",
        fr: "compresser-png/éditeur",
        it: "comprimere-png/editore",
        pt: "compactar-png/editor",
        nl: "png-comprimeren/editor",
        ja: "png圧縮/エディター",
        zh: "压缩png/编辑",
        ko: "png-압축기/편집기",
        sv: "komprimera-png/redigerare",
        tr: "png-sıkıştır/düzenleyici",
        id: "kompres-png/editor",
        pl: "kompresuj-png/edytor",
    },
    "/gif-compressor": {
        en: "gif-compressor",
        bn: "কম্প্রেস-gif",
        es: "comprimir-gif",
        de: "gif-komprimieren",
        si: "සම්පීඩනය-gif",
        fr: "compresser-gif",
        it: "comprimere-gif",
        pt: "compactar-gif",
        nl: "gif-comprimeren",
        ja: "gif圧縮",
        zh: "压缩gif",
        ko: "gif-압축기",
        sv: "komprimera-gif",
        tr: "gif-sıkıştır",
        id: "kompres-gif",
        pl: "kompresuj-gif",
    },
    "/gif-compressor/editor": {
        en: "gif-compressor/editor",
        bn: "কম্প্রেস-gif/সম্পাদক",
        es: "comprimir-gif/editor",
        de: "gif-komprimieren/editor",
        si: "සම්පීඩනය-gif/සංස්කාරක",
        fr: "compresser-gif/éditeur",
        it: "comprimere-gif/editore",
        pt: "compactar-gif/editor",
        nl: "gif-comprimeren/editor",
        ja: "gif圧縮/エディター",
        zh: "压缩gif/编辑",
        ko: "gif-압축기/편집기",
        sv: "komprimera-gif/redigerare",
        tr: "gif-sıkıştır/düzenleyici",
        id: "kompres-gif/editor",
        pl: "kompresuj-gif/edytor",
    },
    "/image-converter": {
        en: "image-converter",
        bn: "ইমেজ-কনভার্টার",
        es: "convertidor-de-imágenes",
        de: "bildkonverter",
        si: "රූප-පරිවර්තකය",
        fr: "convertisseur-d'image",
        it: "convertitore-di-immagini",
        pt: "conversor-de-imagem",
        nl: "afbeelding-converter",
        ja: "画像変換",
        zh: "图像转换器",
        ko: "이미지-변환기",
        sv: "bildkonverterare",
        tr: "görüntü-dönüştürücü",
        id: "konverter-gambar",
        pl: "konwerter-obrazów",
    },
    "/image-converter/editor": {
        en: "image-converter/editor",
        bn: "ইমেজ-কনভার্টার/সম্পাদক",
        es: "convertidor-de-imágenes/editor",
        de: "bildkonverter/editor",
        si: "රූප-පරිවර්තකය/සංස්කාරක",
        fr: "convertisseur-d'image/éditeur",
        it: "convertitore-di-immagini/editore",
        pt: "conversor-de-imagem/editor",
        nl: "afbeelding-converter/editor",
        ja: "画像変換/エディター",
        zh: "图像转换器/编辑",
        ko: "이미지-변환기/편집기",
        sv: "bildkonverterare/redigerare",
        tr: "görüntü-dönüştürücü/düzenleyici",
        id: "konverter-gambar/editor",
        pl: "konwerter-obrazów/edytor",
    },
    "/image-converter/download/:taskId": {
        en: "image-converter/download/:taskId",
        bn: "ইমেজ-কনভার্টার/ডাউনলোড/:taskId",
        es: "convertidor-de-imágenes/descargar/:taskId",
        de: "bildkonverter/download/:taskId",
        si: "රූප-පරිවර්තකය/බාගන්න/:taskId",
        fr: "convertisseur-d'image/télécharger/:taskId",
        it: "convertitore-di-immagini/scarica/:taskId",
        pt: "conversor-de-imagem/baixar/:taskId",
        nl: "afbeelding-converter/download/:taskId",
        ja: "画像変換/ダウンロード/:taskId",
        zh: "图像转换器/下载/:taskId",
        ko: "이미지-변환기/다운로드/:taskId",
        sv: "bildkonverterare/ladda-ner/:taskId",
        tr: "görüntü-dönüştürücü/indir/:taskId",
        id: "konverter-gambar/unduh/:taskId",
        pl: "konwerter-obrazów/pobierz/:taskId",
    },
    "/bulk-resize": {
        en: "bulk-resize",
        bn: "বাল্ক-রিসাইজ",
        es: "cambio-de-tamaño-masivo",
        de: "bulk-bildgrößenänderung",
        si: "තොග-රූප-ප්‍රමාණය-වෙනස්-කරන්නා",
        fr: "redimensionneur-d'image-en-masse",
        it: "ridimensionatore-di-immagini-in-blocco",
        pt: "redimensionador-de-imagem-em-massa",
        nl: "bulk-formaat-wijzigen",
        ja: "一括リサイズ",
        zh: "批量调整大小",
        ko: "대량-크기-변경",
        sv: "massändra-storlek",
        tr: "toplu-boyutlandır",
        id: "ubah-ukuran-massal",
        pl: "zmień-rozmiar-hurtowo",
    },
    "/bulk-resize/editor": {
        en: "bulk-resize/editor",
        bn: "বাল্ক-রিসাইজ/সম্পাদক",
        es: "cambio-de-tamaño-masivo/editor",
        de: "bulk-bildgrößenänderung/editor",
        si: "තොග-රූප-ප්‍රමාණය-වෙනස්-කරන්නා/සංස්කාරක",
        fr: "redimensionneur-d'image-en-masse/éditeur",
        it: "ridimensionatore-di-immagini-in-blocco/editore",
        pt: "redimensionador-de-imagem-em-massa/editor",
        nl: "bulk-formaat-wijzigen/editor",
        ja: "一括リサイズ/エディター",
        zh: "批量调整大小/编辑",
        ko: "대량-크기-변경/편집기",
        sv: "massändra-storlek/redigerare",
        tr: "toplu-boyutlandır/düzenleyici",
        id: "ubah-ukuran-massal/editor",
        pl: "zmień-rozmiar-hurtowo/edytor",
    },
    "/bulk-resize/download/:taskId": {
        en: "bulk-resize/download/:taskId",
        bn: "বাল্ক-রিসাইজ/ডাউনলোড/:taskId",
        es: "cambio-de-tamaño-masivo/descargar/:taskId",
        de: "bulk-bildgrößenänderung/download/:taskId",
        si: "තොග-රූප-ප්‍රමාණය-වෙනස්-කරන්නා/බාගත/:taskId",
        fr: "redimensionneur-d'image-en-masse/téléchargement/:taskId",
        it: "ridimensionatore-di-immagini-in-blocco/scarica/:taskId",
        pt: "redimensionador-de-imagem-em-massa/download/:taskId",
        nl: "bulk-formaat-wijzigen/download/:taskId",
        ja: "一括リサイズ/ダウンロード/:taskId",
        zh: "批量调整大小/下载/:taskId",
        ko: "대량-크기-변경/다운로드/:taskId",
        sv: "massändra-storlek/ladda-ner/:taskId",
        tr: "toplu-boyutlandır/indir/:taskId",
        id: "ubah-ukuran-massal/unduh/:taskId",
        pl: "zmień-rozmiar-hurtowo/pobierz/:taskId",
    },

    "/color-picker": {
        en: "color-picker",
        bn: "রঙ-চয়নকারী",
        es: "selector-de-color",
        de: "farbwähler",
        si: "වර්ණ-තෝරා-ගැනීම",
        fr: "pipette-À-couleurs",
        it: "color-picker",
        pt: "seletor-de-cores",
        nl: "kleurkiezer",
        ja: "カラーピッカー",
        zh: "颜色选择器",
        ko: "색상 선택기",
        sv: "färgväljare",
        tr: "renk-seçici",
        id: "pemilih-warna",
        pl: "wybór-koloru",
    },
    "/rotate-image": {
        en: "rotate-image",
        bn: "চিত্র-ঘোরান",
        es: "rotar-imagen",
        de: "bild-drehen",
        si: "රූපය-කරකවන්න",
        fr: "faire-pivoter-l'image",
        it: "ruota-immagine",
        pt: "girar-imagem",
        nl: "afbeelding-roteren",
        ja: "画像を回転",
        zh: "旋转图像",
        ko: "이미지 회전",
        sv: "rotera-bild",
        tr: "resim-döndür",
        id: "putar-gambar",
        pl: "obracanie-obrazu",
    },
    "/flip-image": {
        en: "flip-image",
        bn: "ফ্লিপ-ইমেজ",
        es: "girar-la-imagen",
        de: "bild-kippen",
        si: "රූපය-පෙරළන්න",
        fr: "retourner-l'image",
        it: "capovolgi-immagine",
        pt: "inverter-imagem",
        nl: "afbeelding-omdraaien",
        ja: "画像を反転",
        zh: "翻转图像",
        ko: "이미지 뒤집기",
        sv: "vänd-bild",
        tr: "resim-çevir",
        id: "membalik-gambar",
        pl: "odwróć-obraz",
    },
    "/image-enlarger": {
        en: "image-enlarger",
        bn: "ইমেজ-বর্ধক",
        es: "ampliadora-de-imagen",
        de: "bildvergrößerer",
        si: "රූපය-විශාල-කිරීම",
        fr: "agrandisseur-d'images",
        it: "ingranditore-immagine",
        pt: "ampliador-de-imagem",
        nl: "afbeelding-vergroter",
        ja: "画像拡大",
        zh: "图像放大器",
        ko: "이미지 확대기",
        sv: "bildförstorare",
        tr: "görüntü-büyütücü",
        id: "pembesar-gambar",
        pl: "powiększanie-obrazu",
    },
    "/image-enlarger/editor": {
        en: "image-enlarger/editor",
        bn: "ইমেজ-বর্ধক/সম্পাদক",
        es: "ampliadora-de-imagen/editor",
        de: "bildvergrößerer/editor",
        si: "රූපය-විශාල-කිරීම/සංස්කාරක",
        fr: "agrandisseur-d'images/éditeur",
        it: "ingranditore-immagine/editore",
        pt: "ampliador-de-imagem/editor",
        nl: "afbeelding-vergroter/editor",
        ja: "画像拡大/エディター",
        zh: "图像放大器/编辑",
        ko: "이미지 확대기/편집기",
        sv: "bildförstorare/redigerare",
        tr: "görüntü-büyütücü/düzenleyici",
        id: "pembesar-gambar/editor",
        pl: "powiększanie-obrazu/edytor",
    },
    "/about": {
        en: "about",
        bn: "সম্পর্কিত",
        es: "sobre",
        de: "um",
        si: "පිළිබඳ",
        fr: "sur",
        it: "di",
        pt: "cerca-de",
        nl: "over",
        ja: "約",
        zh: "关于",
        ko: "약",
        sv: "om",
        tr: "hakkında",
        id: "tentang",
        pl: "o",
    },
    "/privacy": {
        en: "privacy",
        bn: "গোপনীয়তা",
        es: "privacidad",
        de: "privatsphäre",
        si: "පෞද්ගලිකත්වය",
        fr: "intimité",
        it: "intimità",
        pt: "privacidade",
        nl: "privacy",
        ja: "プライバシー",
        zh: "隐私",
        ko: "개인 정보",
        sv: "integritet",
        tr: "gizlilik",
        id: "privasi",
        pl: "prywatność",
    },
    "/terms-of-service": {
        en: "terms-of-service",
        bn: "সেবা-পাবার-শর্ত",
        es: "términos-de-servicio",
        de: "nutzungsbedingungen",
        si: "සේවා-කොන්දේසි",
        fr: "conditions-d'utilisation",
        it: "termini di servizio",
        pt: "termos-de-serviço",
        nl: "servicevoorwaarden",
        ja: "利用規約",
        zh: "服务条款",
        ko: "서비스 약관",
        sv: "användarvillkor",
        tr: "hizmet-şartları",
        id: "syarat-layanan",
        pl: "warunki-usługi",
    },
    "/meme-generator": {
        en: "meme-generator",
        bn: "মেম-জেনারেটর",
        es: "generador-de-memes",
        de: "meme-generator",
        si: "meme-generator",
        fr: "générateur-de-mèmes",
        it: "generatore-di-meme",
        pt: "gerador-de-memes",
        nl: "meme-generator",
        ja: "ミームジェネレーター",
        zh: "表情包生成器",
        ko: "밈 생성기",
        sv: "meme-generator",
        tr: "meme-üretici",
        id: "pembuat-meme",
        pl: "generator-memów",
    },
    "/meme-generator/edit/:slug": {
        en: "meme-generator/edit/:slug",
        bn: "মেম-জেনারেটর/সম্পাদনা/:slug",
        es: "generador-de-memes/editar/:slug",
        de: "meme-generator/bearbeiten/:slug",
        si: "meme-generator/සංස්කරණය/:slug",
        fr: "générateur-de-mèmes/modifier/:slug",
        it: "generatore-di-meme/modificare/:slug",
        pt: "gerador-de-memes/editar/:slug",
        nl: "meme-generator/bewerken/:slug",
        ja: "ミームジェネレーター/編集/:slug",
        zh: "表情包生成器/编辑/:slug",
        ko: "밈 생성기/편집/:slug",
        sv: "meme-generator/redigera/:slug",
        tr: "meme-üretici/düzenle/:slug",
        id: "pembuat-meme/edit/:slug",
        pl: "generator-memów/edytuj/:slug",
    },
    "/changelogs": {
        en: "changelogs",
        bn: "changelogs",
        es: "changelogs",
        de: "changelogs",
        si: "changelogs",
        fr: "changelogs",
        it: "changelogs",
        pt: "changelogs",
        nl: "changelogs",
        ja: "変更ログ",
        zh: "更新日志",
        ko: "변경 로그",
        sv: "ändringsloggar",
        tr: "değişiklik-günlükleri",
        id: "log-perubahan",
        pl: "dzienniki-zmian",
    },
} as const;

export type TranslatedRouteSlugs = typeof translatedRoutesSlugs;

export default translatedRoutesSlugs;
